.banner-curso {
  width: 100%;
  height: 450px;
}

.detail-course-container {
  background-color: #68b7ca;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0rem 0%;
  height: 92vh;
  transform: translateY(82px);
  overflow-y: hidden;
}

.group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
  color: #7d7d7d;
  font-size: 20px;
  padding: 1rem 0 0 1rem;
}

.group-icon {
  padding-right: 0.8rem;
}

@media screen and (max-width: 1200px) {
  .summary-html {
    height: 85%;
  }
  .embed-iframe {
    height: 95%;
  }
  .detail-course-container {
    overflow: scroll;
  }
}

.tit-detail-course {
  color: #68b7ca;
  padding-bottom: 3rem;
  font-size: 30px;
}

.btn-evaluacion {
  font-family: Pop-Bold;
  font-size: 16px;
  color: #fff;
  background-color: #006b95;
  border-radius: 25px;
  border: 0;
  padding: 20px 0 16px 0;
  width: 100%;
  margin-top: 10px;
}

.btn-evaluacion:hover {
  background-color: #69b7cb;
}

.tabs-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 0.5rem;
}

.btn-tab-course {
  font-family: Pop-Regular;
  font-size: 13px;
  color: #7d7d7d;
  background-color: #15171f;
  border-radius: 25px;
  border: 1px solid #b2b2b2;
  padding: 1.3rem 1rem;
  margin: 0.5rem 0;
  width: 30%;
}

.btn-tab-course-2 {
  font-family: Pop-Regular;
  font-size: 13px;
  color: #7d7d7d;
  background-color: #15171f;
  border-radius: 25px;
  border: 1px solid #b2b2b2;
  padding: 1.3rem 1rem;
  margin: 0.5rem 0;
  width: 48%;
}

.btn-tab-course:hover {
  background-color: #69b7cb;
  border: 0;
}

.content-container {
  padding: 0 0 0 0;
  background-color: #e7e6e6;
  margin: 0 0 0 0;
  overflow-y: scroll;
}

@media screen and (max-width: 1800px) {
  .btn-tab-course,
  .btn-tab-course-2 {
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .banner-curso {
    height: 100px;
  }
  .btn-tab-course,
  .btn-tab-course-2 {
    width: 100%;
  }
}
